export default [
  {
    path: "administrator",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AdministratorManagementPages/AdministratorListPage"
      ),
  },
  {
    path: "administrator/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AdministratorManagementPages/AdministratorCreatePage"
      ),
  },
  {
    path: "administrator/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AdministratorManagementPages/AdministratorDetailsPage"
      ),
    props: (route) => ({ username: route.params.username }),
  },
  {
    path: "patient-management",
    component: () => import("@/pages/AdminPages/PatientManagementPage"),
    props: true,
  },
];

import { cloneDeep } from "lodash";
import { generateUUID } from "@/utils/profile";

/**
 * Activity supported dosage kinds
 */
const DosageKinds = Object.freeze({
  Duration: "Duration",
  Logic: "Logic",
});

export const ActivitySideShort = Object.freeze({
  Left: "L",
  Right: "R",
});

/**
 * Default activity values, used as fallback
 */
const defaultProgramActivityValues = {
  // Estimated duration for logic based activities.
  defaultEstimatedDuration: 120,
  defaultActivitySettings: {
    level: 1,
    duration: 30,
    side: ActivitySideShort.Left,
    allowSelfCalibration: false,
    useAutoLevel: false,
  },
};

export default class ProgramActivity {
  /**
   * Creates a new program activity from provided data.
   * @param programActivity
   * @param activityDescription Used to setup certain parameters
   * with better default if provided
   */
  constructor(programActivity, activityDescription = undefined) {
    if (!programActivity) {
      programActivity = {};
    }

    this.uuid = programActivity.uuid || generateUUID();
    this.activityId = programActivity.activityId || "";
    this.activitySettings = programActivity.activitySettings
      ? cloneDeep(programActivity.activitySettings)
      : cloneDeep(defaultProgramActivityValues.defaultActivitySettings);
    this.hasEstimatedDuration = programActivity.hasEstimatedDuration || false;

    if (activityDescription) {
      // Fixup the logic based program activity duration if description is provided
      let activityDosageKind =
        activityDescription.activityOptions?.dosage?.kind;
      if (activityDosageKind === DosageKinds.Logic) {
        // Set the newly created activity duration to the estimated one when provided
        let estimatedDuration =
          activityDescription.activityOptions.dosage.estimatedDuration ||
          defaultProgramActivityValues.defaultEstimatedDuration;
        this.activitySettings.duration = estimatedDuration;
        this.hasEstimatedDuration = true;
      }
    }
  }

  static fromActivityDescription(activityDescription) {
    let activitySettings = cloneDeep(
      defaultProgramActivityValues.defaultActivitySettings
    );

    let activityDosageKind = activityDescription?.activityOptions?.dosage?.kind;
    if (activityDosageKind === DosageKinds.Duration) {
      // Set the newly created activity duration to the minimum specified in description.
      let duration = activityDescription?.activityOptions?.dosage?.min;
      if (duration > 0) {
        activitySettings.duration = duration;
      }
    }

    const programActivityData = {
      activityId: activityDescription.id,
      activitySettings: activitySettings,
    };
    return new ProgramActivity(programActivityData, activityDescription);
  }

  isCalibrationMissing(activityDescriptionList) {
    let activityDescription = activityDescriptionList.find(
      (a) => a.id === this.activityId
    );
    if (activityDescription && activityDescription.patientUsage) {
      return (
        !activityDescription.patientUsage.hasValidCalibration(
          this.activitySettings.side
        ) && !this.activitySettings.allowSelfCalibration
      );
    } else {
      return false;
    }
  }

  hasActivityDescription(activityDescriptionList) {
    return (
      activityDescriptionList.findIndex((a) => a.id === this.activityId) !== -1
    );
  }
}

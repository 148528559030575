<template>
  <base-modal
    :show-modal="showModal"
    trigger-class-modal="modal-sm pb-2"
    :close-button="true"
    @close="goBackToLogin"
    blur-modal-layout
  >
    <template #header>
      <span class="text-h3">{{ $t("App.LoginModal.sessionExpired") }}</span>
    </template>

    <warning-box v-if="hasError" type="error" is-content-centered>
      <div class="flex flex-col">
        <span v-for="(message, id) in errorMessages" :key="id">
          {{ message }}
        </span>
        <div v-if="lockoutWarningMessage">
          {{ lockoutWarningMessage }}
        </div>
      </div>
    </warning-box>
    <login-form
      @logged="onLoginSuccess"
      @loginError="displayLoginError"
      class="mb-2"
    />
  </base-modal>
</template>

<script>
import BaseModal from "@/components/ui/BaseModal.vue";
import LoginForm from "@/components/login/LoginForm.vue";
import WarningBox from "@/components/ui/WarningBox.vue";
import Vue from "vue";
import Store from "@/store";

const eventbus = new Vue();

export default {
  components: {
    BaseModal,
    WarningBox,
    LoginForm,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      username: "",
      password: "",
      hasError: false,
      errorMessages: [],
      lockoutWarningMessage: "",
      resolve: null,
      reject: null,
    };
  },
  created() {
    // Handle opening the login modal event
    eventbus.$on("openLoginModal", (resolve, reject) => {
      // Display the loginModal when not already a login page or not when going towards it.
      const currentPageContainsLogin =
        this.$router?.history?.current?.path.includes("login");
      if (!currentPageContainsLogin) {
        this.showModal = true;
      }

      // When the login modal should open but the pending page to be resolved is a login page,
      // let's open it instead.
      const pendingPageRouteContainsLogin =
        this.$router?.history?.pending?.path.includes("login");
      if (pendingPageRouteContainsLogin) {
        this.showModal = false;
        reject();
      }

      this.resolve = resolve;
      this.reject = reject;
    });
  },
  methods: {
    onLoginSuccess() {
      this.showModal = false;
      this.isLoading = false;
      this.hasError = false;

      const token = Store.state.Auth.token;
      if (this.resolve && token) {
        this.resolve(token);
      } else {
        this.reject();
      }
    },
    goBackToLogin() {
      this.showModal = false;
      this.isLoading = false;
      this.$router.push({ name: "logout" });
    },
    displayLoginError(errorMessages, lockoutWarningMessage) {
      this.errorMessages = errorMessages ? errorMessages.split("\n") : [];
      this.lockoutWarningMessage = lockoutWarningMessage;
      this.hasError = true;
    },
  },
};

export const openLoginModal = () => {
  const promise = new Promise((resolve, reject) => {
    eventbus.$emit("openLoginModal", resolve, reject);
  });
  return promise;
};
</script>

export default [
  {
    path: "therapist",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistListPage"
      ),
  },
  {
    path: "therapist/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistCreationPage"
      ),
    props: (route) => ({
      siteId: route.query.siteId,
    }),
  },
  {
    path: "therapist/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistDetailsPage"
      ),
    props: true,
  },
];

import TrainingMaterialAttachment from "@/models/training-material/TrainingMaterialAttachment.model";
import router from "@/router/";
import { Model } from "@vuex-orm/core";

/**
 * The supported training material kinds, used to build playlists.
 */
export const TrainingMaterialKind = Object.freeze({
  OfficeHours: "OfficeHoursEpisode",
  ClinicalTips: "ClinicalTip",
  MindMotionKeyConcepts: "MindMotionKeyConcepts",
  Unsupported: "Unsupported", // hidden playlist for unsupported kinds
});

/**
 * The supported states of a training material
 */
export const TrainingMaterialState = Object.freeze({
  New: "New",
  Displayed: "Displayed",
  Started: "Started",
  Viewed: "Viewed",
});

/**
 * The attachment types
 */
export const TrainingMaterialAttachmentType = Object.freeze({
  Video: "Video",
  PDF: "PDF",
});

/**
 * Therapist training material (mainly videos)
 */
export default class TrainingMaterial extends Model {
  static entity = "trainingMaterial";

  static fields() {
    return {
      id: this.attr(null), // index
      // Can be equal for the same video in different languages
      trainingMaterialId: this.attr(""),
      language: this.attr(""),
      productName: this.attr(""),
      productVersion: this.attr(-1),
      kind: this.attr(TrainingMaterialKind.Unsupported),
      // Order in the playlist
      rank: this.attr(-1),
      title: this.attr(""),
      summary: this.attr(""),
      attachment: this.attr(
        null,
        (element) => new TrainingMaterialAttachment(element)
      ),
      state: this.attr(TrainingMaterialState.New),
      vignetteUrl: this.attr(""),
      tags: this.attr([]),
    };
  }

  static goToMaterialByRank(playlistId, rank) {
    router
      .push({
        path: `/training/playlists/${playlistId}/${rank}`,
      })
      .catch(() => {});
  }

  /**
   * Returns the list of all training material
   */
  static async fetchAllTrainingMaterials() {
    return this.api().get("/training-material", { dataKey: "result" });
  }
}

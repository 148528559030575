// Data stored in the local storage

const refreshTokenStorageName = "mm_refresh_token";
const deviceIdStorageName = "mm_device_id";
const languageStorageName = "mm_language";
const stayLoggedInStorageName = "mm_stay_logged_in";

// Language
export function setLanguage(language) {
  return window.localStorage.setItem(languageStorageName, language);
}

export function getLanguage() {
  let language = window.localStorage.getItem(languageStorageName);
  // Language is set based on the navigator and its validity will be checked when loading the page
  if (!language) {
    language = navigator.language || navigator.languages[0];
    setLanguage(language);
  }
  return language;
}

// Webapp Device ID
export function setLocalStorageDeviceId(deviceId) {
  window.localStorage.setItem(deviceIdStorageName, deviceId);
}

export function getLocalStorageDeviceId() {
  return window.localStorage.getItem(deviceIdStorageName);
}

// Refresh token
export function setLocalStorageRefreshToken(refreshToken) {
  window.localStorage.setItem(refreshTokenStorageName, refreshToken);
}

export function getLocalStorageRefreshToken() {
  return window.localStorage.getItem(refreshTokenStorageName);
}

export function removeLocalStorageRefreshToken() {
  window.localStorage.removeItem(refreshTokenStorageName);
}

// Stay logged in boolean
export function setLocalStorageStayLoggedIn(stayLoggedIn) {
  window.localStorage.setItem(stayLoggedInStorageName, stayLoggedIn);
}

export function getLocalStorageStayLoggedIn() {
  return (
    JSON.parse(window.localStorage.getItem(stayLoggedInStorageName)) === true
  );
}

export function removeLocalStorageStayLoggedIn() {
  window.localStorage.removeItem(stayLoggedInStorageName);
}
